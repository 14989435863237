<template>
  <div class="w-100">
    <div class="content zxlgl-cont min-h-600">
      <el-card class="box-card empty" v-if="empty && !loading">
        <div>
          <img
            src="@/assets/images/common/empty/txlkzt.svg"
            alt="通讯录"
            width="400"
            class="img"
            type="image/svg+xml"
          />
          <p>
            <span>什么都没有，快来新增联系人吧</span
            ><el-button type="primary" round size @click="addNewButton"
              >添加联系人</el-button
            >
          </p>
        </div>
      </el-card>
      <el-card class="zhgl_cont min-h-550 box-card" v-loading="loading" v-else>
        <div class="mod-title">
          通讯录管理（{{ bundle.contacts.used - bundle.contacts.remain }}/{{
            bundle.contacts.used
          }}）
        </div>
        <div class="mar-t-20">
          <el-button type="primary" round @click="addNewButton2"
            >新增联系人</el-button
          >
        </div>
        <div>
          <ul class="mod-list lh-60 average-list">
            <li>
              <h3>
                <span class="num-tit">序号</span>
                <span class="tag w-100">姓名</span>
                <span class="tag w-300">邮箱</span>
                <span class="tag">手机号</span>
                <span class="tag">微信号</span>
                <span class="tag">备注</span>
                <span class="tag">操作</span>
              </h3>
            </li>
            <li v-for="(contact, index) in contacts" :key="contact.id">
              <div class="item">
                <span class="num bg-555" v-if="index == 0">1</span>
                <span class="num bg-689" v-else-if="index == 1">2</span>
                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                <span class="tag w-100">{{ contact.name }}</span>
                <span class="tag w-300">{{
                  !contact.email ? '--' : contact.email
                }}</span>
                <span class="tag h-60">{{
                  !contact.phone ? '--' : contact.phone
                }}</span>
                <span class="tag h-60">{{
                  contact.openid ? contact.wechat_name : '未绑定'
                }}</span>
                <span class="tag h-60">{{
                  !contact.description ? '--' : contact.description
                }}</span>
                <span class="tag">
                  <el-tooltip
                    effect="dark"
                    content="修改联系人"
                    placement="bottom">
                    <i class="iconfont icon-bianji djh" @click="contact_edit(contact)" />
                  </el-tooltip>
                  <el-tooltip
                          effect="dark"
                          content="绑定微信"
                          placement="bottom">
                    <i :class="{ iconfont: true, 'icon-bangding': true }" @click="showQrcode(contact.subscribe_url)" />
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="删除联系人"
                    placement="bottom">
                    <i class="iconfont icon-shanchu" @click="deleteContact(contact, index)" />
                  </el-tooltip>
                </span>
              </div>
              <div v-if="contact.edited" class="form-box mar-t-80">
                <el-row :gutter="20">
                  <el-col class="xzyh_cont">
                    <div class="w-100 ov-h">
                      <div class="bjnr">
                        <span>姓名</span>
                        <el-input
                          placeholder="请输入姓名"
                          v-model.trim="contact.name"
                          class="w-280"
                        ></el-input>
                        <span>邮箱</span>
                        <el-input
                          placeholder="请输入邮箱地址"
                          v-model.trim="contact.email"
                          class="w-280"
                        ></el-input>
                        <span>手机</span>
                        <el-input
                          placeholder="请输入手机号码"
                          v-model.trim="contact.phone"
                          class="w-280"
                        ></el-input>
                      </div>
                    </div>
                    <el-row class="w-100 ov-h mar-t-10" :gutter="50">
                      <el-col :span="17">
                        <div class="bjnr ov-h">
                          <span>备注</span>
                          <el-input
                            placeholder="请输入需要备注的信息"
                            class="w-280"
                            v-model="contact.description"
                          ></el-input>
                          <span>微信</span>

                          <span v-if="contact.openid">{{
                            contact.wechat_name
                          }}</span>
                          <span class="wbd" v-else>未绑定</span>
                          <el-button
                            type="primary"
                            plain
                            size="mini"
                            style="height: 32px;"
                            @click="showQrcode(contact.subscribe_url)"
                          >
                            {{ contact.openid ? '重新绑定微信' : '绑定微信' }}
                          </el-button>
                        </div>
                        <p class="bjnr ov-h mar-t-10">
                          <strong>注意：</strong
                          >绑定微信后，可使用微信公众号接收预警信息，若需更换绑定微信号，请点击右上角重新绑定微信<br />
                          <em>取消关注公众号，将无法接受微信预警。</em
                          >如已关注公公众号，请取消关注后重新绑定
                        </p>
                      </el-col>
                    </el-row>
                    <div class="button-group">
                      <el-button
                        type="primary"
                        round
                        :disabled="saveLoading"
                        v-loading="saveLoading"
                        @click="save(contact)"
                        >确定</el-button
                      >
                      <el-button round @click="contact.edited = false"
                        >取消</el-button
                      >
                    </div>
                  </el-col>
                </el-row>
              </div>
            </li>
          </ul>
          <div class="page-bottom">
            <el-pagination
              :page-size="10"
              :total="total"
              :hide-on-single-page="true"
              :current-page="currentPage"
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
            />
          </div>
        </div>
        <div v-if="addNew" class="form-box mar-t-10">
          <el-row :gutter="20">
            <div class="xzyh_cont xxlxr_box">
              <div class="w-100 ov-h">
                <div class="bjnr">
                  <span>姓名</span>
                  <el-input
                    placeholder="请输入姓名"
                    class="w-280"
                    v-model.trim="newContact.name"
                  ></el-input>
                  <span>邮箱</span>
                  <el-input
                    placeholder="请输入邮箱地址"
                    class="w-280"
                    v-model.trim="newContact.email"
                  ></el-input>
                  <span>手机</span>
                  <el-input
                    placeholder="请输入手机号码"
                    class="w-280"
                    v-model.trim="newContact.phone"
                  ></el-input>
                </div>
              </div>
              <div class="w-100 ov-h mar-t-10">
                <div class="bjnr">
                  <span>备注</span>
                  <el-input
                    placeholder="请输入需要备注的信息"
                    class="w-280"
                    v-model="newContact.description"
                  ></el-input>
                  <span>微信</span>
                  <span class="wbd">请于新增后扫描二维码绑定</span>
                </div>
              </div>
              <p class="bjnr mar-t-10">
                <strong>注意：</strong
                >微信预警需要扫描二维码绑定微信账号后，于预警设定中进行设置。
              </p>
              <div class="button-group">
                <el-button
                  type="primary"
                  round
                  :disabled="saveLoading"
                  v-loading="saveLoading"
                  @click="save(newContact)"
                  >确定</el-button
                >
                <el-button round @click="addNew = false">取消</el-button>
              </div>
            </div>
          </el-row>
        </div>
      </el-card>
      <el-dialog
        :visible.sync="limitDialogVisible"
        class="Dialog_style2 txrs"
        :close-on-click-modal="false"
      >
        <el-row class="w-100 dp_box">
          <el-col :span="13">
            <div class="img">可用通讯人数已达上限</div>
          </el-col>
          <el-col :span="11">
            <p>
              您购买的<strong>{{ bundle.bundle_name }}</strong>
            </p>
            <p class="fw-b">
              可用通讯人数已达上限：<span class="red"
                >{{ bundle.contacts.used }}人</span
              >
            </p>
            <p class="mar-t-10">请删除通讯人后再新增，</p>
            <p>或联系客服人员</p>
          </el-col>
        </el-row>
      </el-dialog>
      <!-- 关注公众号 -->
      <el-dialog
        :close-on-click-modal="false"
        @closed="reload()"
        class="new-dialog"
        :visible.sync="dialogVisible"
      >
        <div class="mod-title" slot="title">绑定预警公众号</div>
        <div class="cont flex">
          <div class="ov-h">
            <qrcode :url="wechatFocusUrl" />
            <p class="mar-t-10 t-c lh-30">
              微信扫一扫<br />
              关注舆情公众号 接收预警
            </p>
          </div>
        </div>
      </el-dialog>
      <confirm-delete
        ref="confirm"
        title="删除联系人"
        confirm="删除"
        @must="mustDel"
      />
      <!--@end 关注公众号 -->
    </div>
    <new-contact ref="createContactComponents"></new-contact>
  </div>
</template>
<script type="application/javascript">
import qrcode from '@components/common/qrcode-item.vue';
import { getContacts, contact_delete, update, create } from '@/api/contacts';
import { isPhone, isEmail } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import confirmDelete from '@components/common/confirm.vue';
import newContact from '@components/common/new-contacts.vue';
const _ = window._;
export default {
  name: 'component-contact',
  components: {
    qrcode,
    confirmDelete,
    newContact
  },
  data() {
    return {
      limitDialogVisible: false,
      dialogVisible: false,
      contacts: [],
      newContact: {},
      addNew: false,
      empty: false,
      loading: false,
      saveLoading: false,
      wechatFocusUrl: '',
      refresh: 0,
      currentPage: 1,
      total: 0
    };
  },
  watch: {},
  mounted() {
    this.loadContacts();
  },
  computed: {
    ...mapGetters(['bundle'])
  },
  methods: {
    reload() {
      this.$emit('reload');
    },
    addNewButton() {
      if (this.bundle.contacts.remain <= 0) {
        this.limitDialogVisible = true;
        return false;
      }
      this.empty = false;
      this.initNewContact();
      //this.addNew = true;
    },
    addNewButton2() {
      this.$refs.createContactComponents.cc_title = '新增联系人';
      this.$refs.createContactComponents.newContactDialogVisible = true;
    },
    contact_edit(contact) {
      let pramas = {
        id: contact.id,
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        description: contact.description
      };
      this.$refs.createContactComponents.cc_title = '编辑联系人';
      this.$refs.createContactComponents.form = pramas;
      this.$refs.createContactComponents.newContactDialogVisible = true;
    },
    initNewContact() {
      this.newContact = {
        name: '',
        email: '',
        wechat: '',
        phone: '',
        description: ''
      };
    },
    loadContacts() {
      this.loading = true;
      getContacts({ page: this.currentPage })
        .then(res => {
          if (res.data.state) {
            const { data, total } = res.data.data;
            let result = data;

            _(result).forEach((item, i) => {
              result[i].edited = false;
            });
            this.contacts = result;
            this.empty = _.size(result) < 1 ? true : false;
            this.total = total;
            this.loading = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('加载用户通讯录失败，服务错误');
        });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadContacts();
    },
    deleteContact(row, index) {
      let textBox = [
        { type: 'warning', text: '此通讯人将不再收到邮箱预警通知' },
        { type: 'warning', text: '此通讯人绑定的微信通知将被解除' }
      ];
      this.$refs.confirm.show(textBox, { id: row.id, index });
    },
    mustDel(data) {
      const { id, index } = data;
      contact_delete(id)
        .then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.bundle.contacts.remain = this.bundle.contacts.remain + 1;
            this.$store.dispatch('storeBundle', this.bundle);
            this.contacts.splice(index, 1);
          } else {
            this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        })
        .catch(() => {
          this.$message.error('删除用户失败，服务错误');
        });
    },
    planUsers(contact) {
      contact.edited = !contact.edited;
    },
    // 是否需要弹出微信公众号
    save(row, isFocusWeichat = false) {
      let contact = JSON.parse(JSON.stringify(row));
      delete contact.edited;
      for (let k in contact) {
        contact[k] = contact[k] === null ? '' : contact[k];
      }
      if (_.size(contact.name) < 2 || _.size(contact.name) > 18) {
        this.$message.warning('请填写联系人姓名，2~18个字符');
        return false;
      }
      if (!contact.email && !contact.phone) {
        this.$message.warning('email和手机号不能同时为空');
        return false;
      }
      if (_.size(contact.email) >= 1 && !isEmail(contact.email)) {
        this.$message.warning('email格式错误');
        return false;
      }
      // 微信和邮箱不能同时为空可仅填写邮箱，填写微信时邮箱或手机号码必填其一
      if (_.size(contact.phone) >= 1 && !isPhone(contact.phone)) {
        this.$message.warning('请填写正确的手机号码');
        return false;
      }
      this.saveLoading = true;
      if (contact.id) {
        // 修改
        update(contact)
          .then(res => {
            if (res.data.state) {
              this.$message.success('更新成功');
            } else {
              this.$message.error(res.data.error);
            }
            this.saveLoading = false;
          })
          .catch(() => {
            this.saveLoading = false;
            this.$message.error('修改通讯录失败，服务错误');
          });
      } else {
        if (this.bundle.contacts.remain <= 0) {
          this.limitDialogVisible = true;
          return false;
        }
        // 添加
        create(contact)
          .then(res => {
            if (res.data.state) {
              this.$message.success('创建成功');
              this.bundle.contacts.remain = this.bundle.contacts.remain - 1;
              this.$store.dispatch('storeBundle', this.bundle);
              this.initNewContact();
              this.dialogVisible = true;
              this.wechatFocusUrl = res.data.data.subscribe_url;
              this.loadContacts();
              this.addNew = !this.addNew;
            } else {
              this.$message.error(res.data.error);
            }
            this.saveLoading = false;
          })
          .catch(() => {
            this.saveLoading = false;
            this.$message.error('创建联系人失败，服务错误');
          });
      }
    },
    showQrcode(wechatFocusUrl) {
      this.dialogVisible = true;
      this.wechatFocusUrl = wechatFocusUrl;
    }
  }
};
</script>
<style scoped>
.zxlgl-cont >>> .el-card__body {
  padding: 40px;
}
.zxlgl-cont .mod-title {
  margin-left: 0;
}
.zxlgl-cont .num-tit {
  width: 100px;
}
.zxlgl-cont .num {
  margin-right: 82px;
}
.zxlgl-cont li {
  height: auto;
}
.mar-t-80 {
  margin-top: 60px;
}
.bjnr span,
.bjnr .el-input {
  float: left;
  margin-right: 10px;
}
.bjnr .el-input {
  margin-right: 30px;
}
.wbd {
  width: auto;
  font-size: 12px;
  font-weight: 400;
  color: #636363;
}
p.bjnr,
.bjnr {
  line-height: 30px;
}
.h-60 {
  height: 60px;
}
/*二维码*/
.code_box {
  margin-left: -20px;
}
.code_box p {
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 10px;
}
.xzyh_cont >>> .el-button span {
  float: none;
}
.h-60 {
  height: 60px;
}
</style>
